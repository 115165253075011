
<template>
  <div id="data-list-list-view" class="data-list-container">

    <category-sidebar-form :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" />

    <vs-table ref="table" multiple v-model="selected" pagination :max-items="itemsPerPage" search :data="categories">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ACTION - DROPDOWN -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">

            <div class="p-3 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32">
              <span class="mr-2">Actions</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>No Action</span>
                </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <!-- ADD NEW -->
          <div class="btn-add-new p-2 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">Add New</span>
          </div>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-sm-0 mr-4 items-per-page-handler">
          <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ categories.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : categories.length }} of {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage=5">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="id">Id</vs-th>
        <vs-th>Image</vs-th>
        <vs-th sort-key="name">Name</vs-th>
        <vs-th sort-key="is_active">Active</vs-th>
        <vs-th sort-key="position">position</vs-th>
        <vs-th>Action</vs-th>
      </template>

        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p class=" font-medium truncate">{{ tr.id }}</p>
              </vs-td>
               <vs-td class="img-container">
                  <img :src="absoluteUrl(tr.thumb_path)" class="product-img" @click="$router.push(`/categories/${tr.id}`).catch(() => {})" />
                </vs-td>

              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.name | truncate(30)}}</p>
              </vs-td>
              <vs-td>
                <vs-chip :color="getStatusColor(tr.is_active)"
                 class="product-order-status">{{ tr.is_active? '✓' :'X' }}</vs-chip>
              </vs-td>

              <vs-td>
                <vs-input-number :disabled="true" :value="tr.position" />
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="$router.push(`/categories/${tr.id}`).catch(() => {})" />
                <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.id,tr.name)" />
              </vs-td>

            </vs-tr>
          </tbody>
        </template>
    </vs-table>
  </div>
</template>

<script>
import CategorySidebarForm from '@/components/forms/CategorySidebarForm.vue'

export default {
  components: {
    CategorySidebarForm
  },
  data() {
    return {
      selected: [],
      itemsPerPage: 5,
      isMounted: false,
      addNewDataSidebar: false,
      awaitingDelete: "",
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    categories() {
      return this.$store.state.categoryList.categories
    },
    queriedItems() {
      return this.$refs.table 
        ? this.$refs.table.queriedResults.length 
        : this.categories.length
    },
  },
  methods: {
    addNewData() {
      this.toggleDataSidebar(true)
    },
    deleteData(id, name) {
      this.awaitingDelete = id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure ?',
        text: name +' category will be removed!',
        accept: this.acceptDelete
      })
    },
    getParentName(parentId){
      if(!parentId){return}
      return this.categories.find(cat => cat.id == parentId).name
    },
    acceptDelete(){
      if(!this.awaitingDelete){return}
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.delete(`/categories/${this.awaitingDelete}/delete`)
      .then((response) =>{
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.commit('categoryList/DELETE_CATEGORY', this.awaitingDelete);
        }
      })
    },
    toggleDataSidebar(val=false) {
      this.addNewDataSidebar = val
    },
  },
  mounted() {
    this.isMounted = true;
  }

}
</script>


<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
